import React from 'react';
import { GRAY_1 } from '../../constants';

const ArrowBackIcon = ({
  fill = { GRAY_1 },
  width = '24px',
  height = '24px',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={fill}
    width={width}
    height={height}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
  </svg>
);

export default ArrowBackIcon;
