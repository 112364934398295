import React from "react";

const LanguageFlagLT = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="25px"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
  >
    <path
      fill="#6DA544"
      opacity="1.000000"
      stroke="none"
      d="
M1.000004,289.000000 
   C1.000000,267.979095 1.000000,246.958206 1.410169,225.599762 
   C2.575746,220.855743 3.012635,216.365570 4.144449,212.058014 
   C8.015811,197.323975 12.131302,182.654083 16.612518,167.972931 
   C171.718796,167.981201 326.366211,167.976959 481.013641,167.968491 
   C486.345459,167.968201 491.677246,167.925888 497.330139,168.091187 
   C504.329163,185.490341 508.341278,203.366196 511.063782,221.577057 
   C511.244751,222.787491 512.333496,223.862183 513.000000,225.000000 
   C513.000000,246.020889 513.000000,267.041779 512.587402,288.424316 
   C511.808472,289.289154 511.210114,289.750275 511.110077,290.301758 
   C509.370697,299.891968 508.219391,309.625641 505.850891,319.054474 
   C503.540161,328.253265 499.999176,337.142975 496.533569,346.105011 
   C336.101318,346.028687 176.133820,346.015747 16.085167,345.624329 
   C9.803368,327.737610 4.853006,309.930420 2.915632,291.378998 
   C2.826820,290.528595 1.663092,289.790436 1.000004,289.000000 
z"
    />
    <path
      fill="#D80027"
      opacity="1.000000"
      stroke="none"
      d="
M16.166319,346.002777 
   C176.133820,346.015747 336.101318,346.028687 496.530945,346.486206 
   C491.585480,363.507568 483.433167,378.711517 474.264374,393.482178 
   C458.530548,418.828888 438.621460,440.429047 415.406372,458.919220 
   C395.873810,474.476379 374.380188,486.952301 350.999023,495.886292 
   C331.585999,503.304016 311.698181,509.038788 290.895020,511.093658 
   C290.207306,511.161621 289.629211,512.339539 289.000000,513.000000 
   C267.979095,513.000000 246.958206,513.000000 225.607361,512.591553 
   C220.233978,511.128082 215.223145,509.870544 210.141510,509.053070 
   C188.367386,505.550140 167.670685,498.590088 147.767883,489.310669 
   C129.133896,480.622864 111.919823,469.622559 95.956345,456.582031 
   C77.688477,441.658997 61.599930,424.800079 47.858940,405.613831 
   C34.624355,387.134674 24.175508,367.231445 16.166319,346.002777 
z"
    />
    <path
      fill="#FFDA44"
      opacity="1.000000"
      stroke="none"
      d="
M497.009064,167.903168 
   C491.677246,167.925888 486.345459,167.968201 481.013641,167.968491 
   C326.366211,167.976959 171.718796,167.981201 16.667509,167.612976 
   C22.550219,153.989426 27.975266,140.239609 35.305531,127.594223 
   C49.859489,102.487297 68.563667,80.655701 90.672630,61.717228 
   C104.674904,49.722916 119.728371,39.316036 135.926025,30.661051 
   C163.243530,16.064329 192.238525,6.478059 223.120056,2.919686 
   C223.811264,2.840040 224.376175,1.664397 225.000000,1.000001 
   C246.020889,1.000000 267.041779,1.000000 288.193542,1.334177 
   C289.324097,2.111223 290.283264,2.751947 291.329803,2.966617 
   C302.889404,5.337767 314.566742,7.222181 326.009277,10.057357 
   C346.791931,15.206774 366.341034,23.616179 384.794800,34.420860 
   C398.965210,42.717621 412.445740,51.992626 424.708984,63.060146 
   C437.569550,74.666695 450.219940,86.448036 460.173065,100.694595 
   C468.412140,112.487663 476.726135,124.324776 483.720459,136.860184 
   C489.175018,146.635895 492.652832,157.514603 497.009064,167.903168 
z"
    />
  </svg>
);

export default LanguageFlagLT;
